.loading {
  display: inline-block;
  text-align: center;
}

.box {
  display: inline-block;
  height: 100%;
  animation: loading 1.5s infinite ease-in-out both;
  background-color: var(--colors-medium-light);
}

@keyframes loading {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.6);
  }
}
