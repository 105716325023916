.title {
  margin-top: 0;
  margin-bottom: 0.6em;
  font-size: 2em;
  font-weight: 500;
  text-align: left;
  text-transform: lowercase;
}

.slug::before {
  content: "\002E\002F"; /* "./" */
  letter-spacing: 0.1em;
  color: var(--colors-medium-light);
  margin-right: -0.1em;
}
