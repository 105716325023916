.footer {
  flex: 1;
  width: 100%;
  padding: 1.25em 1.5em;
  border-top: 1px solid var(--colors-kinda-light);
  background-color: var(--colors-background-outer);
  color: var(--colors-medium-dark);
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  font-size: 0.8em;
  line-height: 2.3;
}

.link {
  color: var(--colors-medium-dark) !important;
}

.link:has(.icon):hover,
.link:has(.icon):focus-visible {
  color: var(--colors-medium) !important;
}

.link.underline {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--colors-light);
}

.link.underline:hover,
.link.underline:focus-visible {
  border-bottom-color: var(--colors-kinda-light);
}

.icon {
  display: inline;
  width: 1.25em;
  height: 1.25em;
  vertical-align: -0.25em;
  margin: 0 0.1em;
}

.heart {
  display: inline-block;
  color: var(--colors-error);
}

@media (prefers-reduced-motion: no-preference) {
  .heart {
    animation: pulse 10s ease 7.5s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    2% {
      transform: scale(1.25);
    }
    4% {
      transform: scale(1);
    }
    6% {
      transform: scale(1.2);
    }
    8% {
      transform: scale(1);
    }

    /* pause for ~9 out of 10 seconds */
    100% {
      transform: scale(1);
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 1em 1.25em;
  }

  /* stack columns on left instead of flexboxing across */
  .row {
    display: block;
  }
}
