.header {
  width: 100%;
  height: 4.5em;
  padding: 0.7em 1.5em;
  border-bottom: 1px solid var(--colors-kinda-light);
  background-color: var(--colors-background-header);

  /* make sticky */
  position: sticky;
  top: 0;
  z-index: 1000;

  /* blurry glass-like background effect (except on firefox...?) */
  backdrop-filter: saturate(180%) blur(5px);
}

.homeImage {
  width: 50px;
  height: 50px;
  border: 1px solid var(--colors-light);
  border-radius: 50%;
}

.homeLink {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  color: var(--colors-medium-dark) !important;
}

.homeLink:hover,
.homeLink:focus-visible {
  color: var(--colors-link) !important;
}

.name {
  margin: 0 0.6em;
  font-size: 1.15em;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .header {
    padding: 0.75em 1.25em;
    height: 5.9em;
  }

  .homeImage {
    width: 70px;
    height: 70px;
    border-width: 2px;
  }

  .homeLink:hover .homeImage,
  .homeLink:focus-visible .homeImage {
    border-color: var(--colors-link-underline);
  }

  .name {
    display: none;
  }

  .menu {
    max-width: 325px;
  }
}

@media (max-width: 380px) {
  .menu {
    max-width: 225px;
  }
}
