.grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  line-height: 1.1;
  gap: 1em;
}

.card {
  flex-grow: 1;
  width: 370px;
  padding: 1.2em 1.2em 0.8em;
  border: 1px solid var(--colors-kinda-light);
  border-radius: 1em;
  font-size: 0.9em;
  color: var(--colors-medium-dark);
}

.card .name {
  display: inline-block;
  margin-bottom: 0.4em;
  font-size: 1.2em;
  font-weight: 600;
}

.card .description {
  margin: 0;
  line-height: 1.7;
}

.card .meta {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4em;
}

.card .metaItem {
  margin: 0.3em 1.5em 0.3em 0;
  color: var(--colors-medium);
  white-space: nowrap;
}

.card .metaLink {
  color: inherit;
}

.card .metaLink:hover,
.card .metaLink:focus-visible {
  color: var(--colors-link);
}

.card .metaIcon {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.5em;
  vertical-align: text-top;
}
