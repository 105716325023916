.meta {
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 0.925em;
  line-height: 2.3;
  letter-spacing: 0.04em;
  color: var(--colors-medium);
}

.meta .metaItem {
  margin-right: 1.6em;
  white-space: nowrap;
}

.meta .metaLink {
  color: inherit;
}

.meta .metaIcon {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  vertical-align: -0.25em;
  margin-right: 0.6em;
}

.meta .metaTags {
  white-space: normal;
  display: inline-flex;
  flex-wrap: wrap;
}

.meta .metaTag {
  text-transform: lowercase;
  white-space: nowrap;
  margin-right: 0.75em;
}

.meta .metaTag::before {
  content: "\0023"; /* cosmetically hashtagify tags */
  padding-right: 0.125em;
  color: var(--colors-light);
}

.meta .metaTag:last-of-type {
  margin-right: 0;
}

.title {
  margin: 0.3em 0 0.5em -1px; /* misaligned left margin, super nitpicky */
  font-size: 2.3em;
  line-height: 1.3;
  font-weight: 700;
}

.title code {
  margin: 0 0.075em;
}

.title .link {
  color: var(--colors-text);
}

.comments {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 2px solid var(--colors-light);
  min-height: 140px;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.9em;
  }
}
